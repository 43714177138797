import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useLayoutEffect, useState } from 'react'

import text from './text.json'

type Contents = typeof text['it']
const validLanguageCodes = Object.keys(text)

export default memo(function Error404() {
  const [contents, setContents] = useState<Contents>(text.en)

  useLayoutEffect(() => {
    const language =
      (window.navigator as any).userLanguage || window.navigator.language
    const languageCode = language.substring(0, 2).toLowerCase()

    if (validLanguageCodes.includes(languageCode)) {
      setContents(text[languageCode as 'it'])
    }
  }, [])

  return (
    <Container>
      <Wrapper>
        <Logo
          src="/logo.svg"
          alt="Hotel Corallo Sorrento"
          width="152"
          height="77.5"
        />
        <Title>{contents.title}</Title>
        <Text>{contents.text}</Text>
        <CTA
          label={useVocabularyData('back-to-home', contents.code)}
          URL={`/${contents.prefix}`}
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
  text-align: center;
  transform: translateY(-50%);
`

const Logo = styled.img`
  margin-bottom: 4.125rem;

  @media (max-width: 1199px) {
    margin-bottom: 2.125rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 4.375rem;
  line-height: 2.8125rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
`

const Text = styled.div`
  margin-top: 2.125rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.8125rem;

  @media (max-width: 1199px) {
    margin-top: 1.125rem;
  }
`

const CTA = styled(Button)`
  margin: 3.75rem auto 0;

  @media (max-width: 1199px) {
    margin-top: 2.75rem;
  }
`
